/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Badge,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  Notifications as NotificationsIcon,
  ManageAccounts as ManageAccountsIcon,
  Logout as LogoutIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";

import HabitBetterLogo from "assets/Images/HabitBetterLogoMini.svg";
import {
  FetchUserDetailsQuery,
  GetNotificationsQuery,
  LogoutUserQuery,
  ReadNotificationQuery,
} from "pages/Login/LoginQueries";
import RouteConstants from "routes/RouteConstants";

const Header = () => {
  const navigate = useNavigate();

  //states
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [showBadge, setShowBadge] = useState(false);

  //queries
  const { data: userData } = FetchUserDetailsQuery();
  const { mutate: logoutUser, isSuccess: logoutSuccess } = LogoutUserQuery();
  const { data: notificationsData } = GetNotificationsQuery();
  const { mutateAsync: readNotification } = ReadNotificationQuery();

  const openNotificationMenuStatus = Boolean(notificationAnchorEl);
  const openProfileMenuStatus = Boolean(profileAnchorEl);

  //handlers
  const handleClickNotificationMenu = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    readNotification().then(() => {
      setShowBadge(false);
    });
    setNotificationAnchorEl(null);
  };
  const handleClickProfileMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setProfileAnchorEl(null);
  };

  //useEffects
  useEffect(() => {
    if (logoutSuccess) {
      localStorage.clear();
      navigate(RouteConstants.LOGIN);
    }
  }, [logoutSuccess]);

  useEffect(() => {
    if (
      Array.isArray(notificationsData?.data) &&
      notificationsData.data.length > 0
    ) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [notificationsData?.data]);

  return (
    <header
      className='flex justify-between bg-white px-8'
      style={{
        boxShadow: "0px 4px 12px #455E7C0D",
        position: "sticky",
        top: 0,
        zIndex: 100,
        marginBottom: 19,
        height: 80,
      }}>
      {/* <h1 className="flex  items-center	text-hb_blue-500 font-bold text-lg">
        LOGO
      </h1> */}
      <div className='flex cursor-pointer items-center'>
        <Link to='/dashboard'>
          <img
            className='h-auto w-12'
            src={HabitBetterLogo}
            alt='HabitBetter Logo'
          />
        </Link>
      </div>

      <div className='flex items-center justify-between'>
        <div>
          <IconButton
            id='fade-button'
            aria-controls={openNotificationMenuStatus ? "fade-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={openNotificationMenuStatus ? "true" : undefined}
            style={{ color: "black" }}>
            <Box
              sx={{ color: "action.active" }}
              onClick={handleClickNotificationMenu}>
              {showBadge ? (
                <Badge
                  sx={{
                    "& .MuiBadge-dot": {
                      padding: "3px",
                      marginTop: "6px",
                      minWidth: "6px",
                      width: "6px",
                      height: "6px",
                      background: "#ff4d4f",
                      marginRight: "7px",
                    },
                  }}
                  className='mx-2'
                  variant='dot'>
                  <NotificationsIcon className='text-hb_blue-800  ' />
                </Badge>
              ) : (
                <NotificationsIcon className='text-hb_blue-800  ' />
              )}
            </Box>
          </IconButton>
          <Menu
            anchorEl={notificationAnchorEl}
            open={openNotificationMenuStatus}
            onClose={handleCloseNotificationMenu}
            sx={{
              "& .MuiPaper-root": {
                minWidth: 300,
                borderRadius: "10px",
                paddingBottom: "1rem",
              },
              "& .MuiList-root": {
                height: "100%",
              },
            }}>
            {notificationsData?.data &&
            Array.isArray(notificationsData.data) &&
            notificationsData.data.length > 0 ? (
              <>
                <div className='p-2'>
                  <h1 className='font-semibold text-hb_blue-800'>
                    NOTIFICATIONS
                  </h1>
                </div>
                <Divider />
                {notificationsData.data.map((item) => (
                  <MenuItem
                    className='flex flex-col items-start space-y-2'
                    divider>
                    <div>
                      <p className='text-hb_blue-800'> {item.data.title}</p>
                    </div>
                    <div>
                      <p>{item.data.description} </p>
                      <div />
                    </div>
                    {/* {item.data.name}
                      {item.data.email}
                      {item.data.title}
                      {item.data.description} */}
                  </MenuItem>
                ))}
              </>
            ) : (
              <div className='flex items-center justify-center'>
                <h1 className='my-auto text-xl text-hb_gray-900'>
                  No new notifications!!
                </h1>
              </div>
            )}
          </Menu>
        </div>
        <span className='mr-2 text-lg font-normal text-hb_blue-800'>
          <p className='user-name capitalize'>
            {userData?.data?.name || "User"}
          </p>
        </span>
        <IconButton
          size='small'
          onClick={() => {
            navigate(RouteConstants.MY_PROFILE);
            handleCloseProfileMenu();
          }}>
          <Avatar
            src={userData?.data?.avatar || ""}
            className='my-auto flex items-center'
            sx={{ width: 48, height: 48 }}
          />
        </IconButton>
        <IconButton
          size='small'
          onClick={handleClickProfileMenu}
          label='menu'
          id='profile-menu'
          aria-controls='profile-menu'
          aria-haspopup='true'
          aria-expanded={openNotificationMenuStatus ? "true" : undefined}>
          <KeyboardArrowDownIcon
            className='cursor-pointer text-3xl text-hb_blue-800'
            sx={{ fontSize: 32, color: "black" }}
          />
        </IconButton>
        <Menu
          className='my-4'
          id='profile-menu'
          anchorEl={profileAnchorEl}
          open={openProfileMenuStatus}
          onClose={handleCloseProfileMenu}
          MenuListProps={{
            "aria-labelledby": "profile-button",
          }}>
          <MenuItem
            onClick={() => {
              navigate(RouteConstants.MY_PROFILE);
              handleCloseProfileMenu();
            }}>
            <ManageAccountsIcon
              fontSize='small'
              className='mr-2 text-hb_blue-800'
            />
            My Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              logoutUser();
            }}>
            <LogoutIcon fontSize='small' className='mr-2 text-hb_blue-800' />
            Log Out
          </MenuItem>
        </Menu>
        <span></span>
      </div>
    </header>
  );
};

export default Header;
