/** @format */

const RouteConstants = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  MY_PROFILE: "/my-profile",
  DASHBOARD: "/dashboard",
  PROMOTION: "/promotion",
  COACH_THERAPIST: "/user-management/coach-therapist",
  GENERAL_USERS: "/user-management/general-users",
  COACH_DETAIL: "/user-management/coach-therapist/:coachUsername",
  USER_DETAIL: "/user-management/general-users/:userUUID",
  MY_COURSES: "/course-management/my-courses",
  DAILY_PRACTICE: "/course-management/daily-practice",
  COURSE_DETAIL: "/course-management/my-courses/:courseId",
  DAILY_PRACTICE_DETAIL: "/course-management/daily-practice/:lessonId",
  MY_HABITS: "/my-habits",
  HABIT_DETAIL: "/my-habits/:habitId",
  CODES_AND_APPROVAL: "/codes-and-approval",
  APPROVALS: "/approvals",
  PROFILE_APPROVALS: "/approvals/profile",
  HABIT_APPROVALS: "/approvals/habit",
  NOTE_APPROVALS: "/approvals/note",
  PLAYGROUND: "/playground",
  LESSON_DETAIL: "/course-management/my-courses/:courseId/:lessonId",
  HABIT_ACTION: "/approvals/habit/:habitactionId",
  PROFILE_DETAIL: "/approvals/profile/:userUUID",
  NOTE_DETAIL: "/approvals/note/:noteId",
};

export const NavigationLinks = [
  {
    name: "Dashboard",
    redirectLink: RouteConstants.DASHBOARD,
  },
  {
    name: "Promotion",
    redirectLink: RouteConstants.PROMOTION,
  },
  {
    name: "User Management",
    children: [
      {
        name: "Coach Therapist",
        redirectLink: RouteConstants.COACH_THERAPIST,
      },
      {
        name: "General User",
        redirectLink: RouteConstants.GENERAL_USERS,
      },
    ],
  },
  {
    name: "Course Management",
    children: [
      {
        name: "Daily Practice",
        redirectLink: RouteConstants.DAILY_PRACTICE,
      },
      {
        name: "My Courses",
        redirectLink: RouteConstants.MY_COURSES,
      },
    ],
  },
  {
    name: "My Habits",
    redirectLink: RouteConstants.MY_HABITS,
  },
  {
    name: "Approvals",
    children: [
      {
        name: "Habits",
        redirectLink: RouteConstants.HABIT_APPROVALS,
      },
      {
        name: "Profiles",
        redirectLink: RouteConstants.PROFILE_APPROVALS,
      },

      {
        name: "Notes",
        redirectLink: RouteConstants.NOTE_APPROVALS,
      },
    ],
  },
];

export default RouteConstants;
