/** @format */

import Header from "components/Header";
import SideNavDrawer from "components/SideNavDrawer";

const Layout = ({ children }) => {
  return (
    <div className='w-screen'>
      <Header />
      <div className='flex'>
        <SideNavDrawer />
        <div className='widthCalc'>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
