/** @format */

import { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import SVG from "react-inlinesvg";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import TableSearch from "./TableSearch";
import { useDebounce } from "utils/CustomHooks";
import { List, MenuItem, Pagination, Select, Tooltip } from "@mui/material";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import IndeterminateCheckbox from "components/IndeterminiteCheckbox";
import { isSchema } from "yup";
export default function DataTableCustom(props) {
  const {
    data,
    columns,
    setSearchHandler,
    emptyDataImage,
    emptyDataMessage,
    placeholder,
    dropdownPageSize,
    selectedNotes,
    setDropdownPageSize,
    selectOtherActions = "",
    setFiltersHandler,
    currentPage,
    pageChangeHandler,
    paginationProps,
    initialStateSortBy,
    isPgination,
    isChecked,
    isSearch,
    setApprovalUser,
  } = props;

  //pagination variables
  let total_count = paginationProps?.total_count;
  let per_page = paginationProps?.per_page;

  //states
  const [searchKeyword, setSearchKeyword] = useState(null);

  //handlers
  const handleSearchUpdate = (text) => {
    setSearchKeyword(text?.trim());
  };
  const getSelectionState = (rows) => {
    const nonDisabledRows = rows?.filter((row) => {
      const isNotePublic = row.original.notes?.some(
        (note) => note.is_public === 1
      );
      const isRowPublic = row.original.public === 1;
      const isProfilePublic = row.original.profile?.public === 1;
      return !(isNotePublic || isRowPublic || isProfilePublic);
    });

    if (nonDisabledRows?.every((row) => row.isSelected)) {
      return "all"; // All non-disabled rows are selected
    } else if (nonDisabledRows.some((row) => row.isSelected)) {
      return "partial"; // Some non-disabled rows are selected
    } else {
      return "none"; // No non-disabled rows are selected
    }
  };

  const toggleSelection = (rows, allRowsSelected) => {
    const shouldSelect = allRowsSelected !== "all";
    rows.forEach((row) => {
      const isNotePublic = row.original.notes?.some(
        (note) => note.is_public === 1
      );
      const isRowPublic = row.original.public === 1;
      const isProfilePublic = row.original.profile?.public === 1;

      if (!(isNotePublic || isRowPublic || isProfilePublic)) {
        row.toggleRowSelected(shouldSelect);
      }
    });
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialStateSortBy || [],
      },
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) =>
        isChecked
          ? [
              // Let's make a column for selection
              {
                id: "selection",
                Header: ({ rows }) => {
                  const allRowsSelected = getSelectionState(rows);

                  return (
                    <div>
                      <IndeterminateCheckbox
                        // checked={allRowsSelected === "all"}
                        indeterminate={allRowsSelected === "partial"}
                        onChange={() => toggleSelection(rows, allRowsSelected)}
                      />
                    </div>
                  );
                },

                Cell: ({ row }) => (
                  <div>
                    {row.original.notes?.some((note) => note.is_public === 1) ||
                    row.original.public === 1 ||
                    row.original.profile?.public === 1 ? (
                      <Tooltip title='Already Approved'>
                        <span>
                          <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                            disabled={true}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <IndeterminateCheckbox
                        {...row.getToggleRowSelectedProps()}
                      />
                    )}
                  </div>
                ),
              },
              ...cols,
            ]
          : [...cols]
      );
    }
  );

  const {
    setPageSize,
    state: { sortBy },
    rows,
    toggleRowSelected,
  } = tableInstance;

  //useEffects
  useEffect(() => {
    if (setFiltersHandler) setFiltersHandler(sortBy);
  }, [sortBy, setFiltersHandler]);

  /* Debouncing the search */
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  useEffect(() => {
    if (setSearchHandler) setSearchHandler(debouncedSearchKeyword);
  }, [debouncedSearchKeyword, setSearchHandler]);
  useEffect(() => {
    // Set the page size
    if (paginationProps) {
      setPageSize(Math.floor(total_count / dropdownPageSize) || 1);
    }
  }, [paginationProps, setPageSize, total_count, dropdownPageSize]);

  const userData = tableInstance.rows.filter(
    (activeData) => activeData?.isSelected === true
  );

  useEffect(() => {
    setApprovalUser?.(userData);
  }, [userData?.length]);

  useEffect(() => {
    // Unselect all rows if selectedNotes is empty
    if (selectedNotes?.length === 0) {
      rows?.forEach((row) => {
        toggleRowSelected(row.id, false);
      });
    }
  }, [selectedNotes?.length, rows, toggleRowSelected]);

  useEffect(() => {
    // Check if there is a new API response
    tableInstance?.toggleAllRowsSelected(false); // Unselect all rows
  }, [tableInstance]);

  return (
    <>
      <div className='flex flex-col space-y-6'>
        <div className='flex items-center justify-between '>
          <div>
            {!isSearch && (
              <TableSearch
                placeholder={placeholder || "Search"}
                value={debouncedSearchKeyword}
                onChange={handleSearchUpdate}
              />
            )}
          </div>

          {!isPgination && (
            <div className='-py-2 z-0 flex items-center justify-center rounded-lg bg-white px-4'>
              <Select
                className='pagination-select mr-4'
                value={dropdownPageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value) || 1);
                  setDropdownPageSize(Number(e.target.value));
                }}
                defaultValue={10}
                IconComponent={KeyboardArrowDownOutlined}
                sx={{
                  "& .MuiSelect-select": {
                    paddingBlock: ".6rem",
                  },
                }}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>

              <List>
                <Pagination
                  count={Math.ceil(total_count / per_page) || 0}
                  shape='rounded'
                  showFirstButton
                  showLastButton
                  page={currentPage || 1}
                  onChange={(e, value) => pageChangeHandler(value)}
                />
              </List>
            </div>
          )}
        </div>
        <div>{selectOtherActions}</div>
        {data?.length > 0 ? (
          <div className='rounded-[10px] bg-white p-4'>
            <table
              {...tableInstance.getTableProps()}
              className='table w-full border-collapse'>
              <thead className='text-semibold align-middle'>
                {
                  // Loop over the header rows
                  tableInstance.headerGroups?.map((headerGroup) => (
                    // Apply the header row props
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className='text-saboo_grey-500	align-middle text-base font-semibold capitalize tracking-normal'>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers?.map((column, idx) => (
                          // Apply the header cell props

                          <th className='border-saboo_grey-50 border-b-2 border-t-0	py-4  text-left'>
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  style: {
                                    width:
                                      isChecked && idx === 0
                                        ? "10px"
                                        : column.width || "100px",
                                  },
                                })
                              )}
                              className='flex items-center space-x-2'>
                              <span
                                className={`text-sm font-medium text-hb_blue-800 ${
                                  column.isSorted ? "sorted" : null
                                } align-middle`}>
                                {
                                  // Render the header
                                  column.render("Header")
                                }
                              </span>
                              <span className={`align-middle`}>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <span className='sorted-svg-icon flex'>
                                      <FaSortAmountDown className='text-hb_orange-dark' />
                                    </span>
                                  ) : (
                                    <span className='sorted-svg-icon'>
                                      <FaSortAmountUp className='text-hb_orange-dark' />
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div
                              style={
                                idx === 0
                                  ? { width: "50px" }
                                  : {
                                      width: column.width || "100px",
                                    }
                              }>
                              {column.Filter ? column.render("Filter") : null}
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>

              <tbody {...tableInstance.getTableBodyProps()}>
                {
                  // Loop over the table rows
                  tableInstance.rows.map((row) => {
                    // Prepare the row for display
                    tableInstance.prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={`text-sm text-hb_black-100 ${
                                  cell.column.isSorted ? "sorted" : "not-sorted"
                                } border-saboo_grey-50 border-t-2 py-4 text-left   align-middle`}>
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className='my-3 flex w-full flex-col items-center justify-center bg-white'
            style={{ minHeight: "400px" }}>
            <div className='p-4'>
              <img src={emptyDataImage} alt='No Data' />
            </div>
            <div>
              <p className='text-black-700 py-1 text-xl font-medium'>
                {emptyDataMessage}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
