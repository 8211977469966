import { BrowserRouter as Router } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import Routes from "routes";
import PrimaryTheme from "themes/PrimaryTheme";
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 7,
      enabled: false,
      staleTime: Infinity,
      retry: 1,
    },
  },
});

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={PrimaryTheme}>
            <ToastContainer closeButton={false} />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
