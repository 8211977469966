import { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListItemButton,
  Typography,
} from "@mui/material";
import { NavigationLinks } from "routes/RouteConstants";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

const SideNavDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //states
  const [navItemSelected, setNavItemSelected] = useState([]);
  const [navItemExpanded, setnavItemExpanded] = useState([]);

  //handlers
  const handleParentNavItemClick = (NavigationItem) => {
    if ("children" in NavigationItem) {
      if (navItemExpanded.includes(NavigationItem.name)) {
        setnavItemExpanded(
          navItemExpanded.filter(
            (navListItems) => navListItems !== NavigationItem.name
          )
        );
      } else {
        setnavItemExpanded([...navItemExpanded, NavigationItem.name]);
      }
    } else {
      navigate(NavigationItem.redirectLink);

      setNavItemSelected(NavigationItem.name);
    }
  };
  const handleChildNavItemClick = (
    NavigationItem,
    NavigationParentItem,
    pushHistory = false
  ) => {
    if (pushHistory) {
      if ("redirectLocation" in NavigationItem) {
        navigate(NavigationItem.redirectLocation());
      } else {
        navigate(NavigationItem.redirectLink);
      }
    }
    setNavItemSelected([NavigationItem.name, NavigationParentItem.name]);
  };

  //useEffects
  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname;
      const navItem = NavigationLinks.find((NavigationItem) => {
        if (NavigationItem.children) {
          return NavigationItem.children.find(
            (child) => child.redirectLink === path
          );
        }
        return NavigationItem.redirectLink === path;
      });

      if (navItem?.name) {
        setNavItemSelected(navItem.name);
      }
    }
    /* if (Array.isArray(NavigationLinks) && NavigationLinks.length > 0) {
      setNavItemSelected(NavigationLinks[0].name);
    } */
  }, []);

  const displayDrawerLinks = (NavigationItem, NavigationIndex) => {
    const isItemSelected = navItemSelected.includes(NavigationItem.name);

    return (
      <div key={`${NavigationItem}${NavigationIndex}`}>
        <ListItemButton
          key={NavigationItem.name}
          selected={isItemSelected}
          onClick={() => handleParentNavItemClick(NavigationItem)}
        >
          <div
            className={`relative -left-3 h-8 w-1 rounded-r-xl  ${
              isItemSelected ? "bg-hb_orange-dark" : "bg-transparent"
            }`}
          ></div>

          <ListItemText
            disableTypography
            primary={
              <Typography type="body2" style={{ fontWeight: "650" }}>
                {NavigationItem.name}
              </Typography>
            }
          />

          {"children" in NavigationItem && (
            <ListItemSecondaryAction>
              <IconButton
                size="small"

                /* onClick={() => handleParentNavItemClick(NavigationItem)} */
              >
                <ArrowForwardIos
                  fontSize="small"
                  className={`${
                    navItemExpanded.includes(NavigationItem.name)
                      ? "rotate-90	"
                      : ""
                  } text-[14px] text-hb_blue-800 `}
                />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
        {"children" in NavigationItem &&
          navItemExpanded.includes(NavigationItem.name) && (
            <List component="div" disablePadding className="fo list-decimal">
              {NavigationItem.children.map((NavigationChildrenItem) =>
                "redirectLink" in NavigationChildrenItem ? (
                  <NavLink
                    to={
                      "redirectLink" in NavigationChildrenItem &&
                      NavigationChildrenItem.redirectLink
                    }
                    key={NavigationChildrenItem.name}
                  >
                    <ListItemButton
                      selected={navItemSelected.includes(
                        NavigationChildrenItem.name
                      )}
                      onClick={() =>
                        handleChildNavItemClick(
                          NavigationChildrenItem,
                          NavigationItem
                        )
                      }
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{ fontWeight: "550" }}
                            className="ml-6"
                          >
                            {NavigationChildrenItem.name}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <ListItemButton
                    key={NavigationChildrenItem.name}
                    selected={navItemSelected.includes(
                      NavigationChildrenItem.name
                    )}
                    onClick={() =>
                      handleChildNavItemClick(
                        NavigationChildrenItem,
                        NavigationItem,
                        true
                      )
                    }
                  >
                    <ListItemText
                      primary={NavigationChildrenItem.name}
                      className="ml-6"
                    />
                  </ListItemButton>
                )
              )}
            </List>
          )}
      </div>
    );
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 240,
        "& .MuiDrawer-paper": {
          width: 240,
        },
        flexShrink: 0,
        "& .MuiPaper-root": {
          marginTop: "6rem",
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))",
        },
      }}
    >
      <List>{NavigationLinks?.map(displayDrawerLinks)}</List>
    </Drawer>
  );
};

export default SideNavDrawer;
