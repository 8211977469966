import React from "react";
import { Breadcrumbs } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

import { styled } from "@mui/material/styles";

const PageHeaderRootDiv = styled("div")(({ theme }) => ({
  borderLeft: "none",
  borderRight: "none",
  padding: theme.spacing(1, 3),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // position: "sticky",
  top: 0,
  zIndex: 10,
  backgroundColor: "#fff",
  minHeight: 60,
  borderRadius: ".5rem",
}));

const PageHeader = (props) => {
  const { pageName, actions, breadCrumbs } = props;

  return (
    <PageHeaderRootDiv
      style={{ boxShadow: "0px 5px 40px rgba(0, 0, 0, 0.05)" }}
    >
      <div className="flex items-center">
        <h2
          className="mr-8"
          style={{ color: "#434349", fontSize: "1.125rem", fontWeight: "500" }}
        >
          {pageName}
        </h2>
        {Array.isArray(breadCrumbs) && breadCrumbs.length > 0 && (
          <Breadcrumbs aria-label="breadcrumb" maxItems={5} separator="•">
            <Link
              className="text-muted hover:text-saboo_blue-800 breadcrumb-item"
              underline="none"
              component={RouterLink}
              to="/dashboard"
            >
              <i className="flaticon2-shelter text-muted icon-1x" />
            </Link>
            {breadCrumbs?.map((breadCrumb, index) => (
              <Link
                className="breadcrumb-item text-muted hover:text-hb_blue-500"
                underline="none"
                component={RouterLink}
                key={index}
                to={breadCrumb.link}
              >
                {breadCrumb.label}
              </Link>
            ))}
          </Breadcrumbs>
        )}
      </div>
      <div className="flex">{actions}</div>
    </PageHeaderRootDiv>
  );
};

PageHeader.defaultProps = {
  pageName: "",
  actions: null,
};

export default PageHeader;
