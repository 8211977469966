import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { useLocalStorage } from "utils/CustomHooks";
import { FetchGeneralUsersListQuery } from "pages/UserManagement/UserManagementQueries";

const AssignToModal = (props) => {
  const {
    open,
    handleAssignToModalToggleStatus,
    handleSubmitSelectedClients,
    isEdit,
    nonAssignableuser,
  } = props;

  //states
  const [type, setType] = useState("individual");
  const [selectedClients, setSelectedClients] = useState([]);
  const [fetchUserPayload, setFetchUserPayload] = useState(null);
  const [mainData, setMainData] = useState(false);

  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useLocalStorage(
    "generalUserList_currentPage",
    1
  );
  const [dropdownPageSize, setDropdownPageSize] = useLocalStorage(
    "generalUserList_dropdownPageSize",
    50
  );
  const [sorts, setSorts] = useLocalStorage("generalUserList_sorts", [
    {
      id: "name",
      desc: false,
    },
  ]);

  //handlers
  const handleTypeChange = (e) => {
    setType(e.target.value);
    setSelectedClients([]);
  };
  const handleSelectClient = (clientId, clientName, status) => {
    const clientIdx = selectedClients.findIndex(
      (client) => client.id === clientId
    );
    if (clientIdx === -1) {
      if (status) {
        setSelectedClients((prevState) => [
          ...prevState,
          { id: clientId, name: clientName },
        ]);
      }
    } else {
      if (!status) {
        setSelectedClients((prevState) => {
          return prevState.filter((client) => client.id !== clientId);
        });
      }
    }
  };
  const setDropdownPageSizeHandler = (count) => {
    setCurrentPage(1);
    setDropdownPageSize(count);
  };
  const pageChangeHandler = (pageNo) => {
    setCurrentPage(pageNo);
  };
  const setSearchHandler = (searchKeyword) => {
    setSearch(searchKeyword);
  };
  const setSortsHandler = (col) => {
    setSorts(col);
  };

  //queries
  const { data: generalUserList } =
    FetchGeneralUsersListQuery(fetchUserPayload);


  //useEffects
  useEffect(() => {
    setFetchUserPayload({
      page: 1,
      per_page: dropdownPageSize,
      search,
      sorts,
    });
  }, [dropdownPageSize]);
  useEffect(() => {
    setFetchUserPayload({
      page: currentPage,
      per_page: dropdownPageSize,
      search,
      sorts,
    });
  }, [currentPage]);
  useEffect(() => {
    if (search || search === "") {
      setCurrentPage(1);
      setFetchUserPayload({
        page: 1,
        per_page: dropdownPageSize,
        search,
        sorts,
      });
    }
  }, [search]);
  useEffect(() => {
    if (sorts) {
      setFetchUserPayload({
        page: currentPage,
        per_page: dropdownPageSize,
        search,
        sorts,
      });
    }
  }, [sorts]);

  useEffect(() => {
    if (isEdit) {
      setMainData(nonAssignableuser?.data);
    } else {
      setMainData(generalUserList?.data?.data);
    }
  }, [isEdit, generalUserList, nonAssignableuser]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleAssignToModalToggleStatus(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>ASSIGN TO</DialogTitle>
        <DialogContent dividers>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                value={type}
                onChange={handleTypeChange}
              >
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio
                      sx={{
                        color: "#FF6C44",
                        "&.Mui-checked": {
                          color: "#FF6C44",
                        },
                      }}
                    />
                  }
                  label="Individual"
                />
              </RadioGroup>
            </FormControl>
            <div className="mb-4">
              <FormGroup>
                {Array.isArray(mainData) && mainData?.length > 0 ? (
                  <>
                    {mainData?.map((client, index) => (
                      <FormControlLabel
                        key={client.id}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              handleSelectClient(
                                client.id,
                                client.name,
                                e.target.checked
                              );
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              "&.Mui-checked": {
                                color: "#FF6C44",
                              },
                            }}
                          />
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "1.125rem",
                            color: "#264265",
                          },
                        }}
                        label={client.name}
                      />
                    ))}
                  </>
                ) : (
                  <div className="mt-4 mb-6 text-center">
                    <p className="text-2xl font-medium text-hb_gray-700">
                      No clients found!
                    </p>
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              background: "#FF6C44 !important",
              color: "white",
            }}
            className="w-full px-6 py-2"
            onClick={() => {
              handleSubmitSelectedClients(selectedClients);
              handleAssignToModalToggleStatus(false);
            }}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AssignToModal;
