import { useAPI } from "services";
import { useToast } from "utils/CustomHooks";
import { useQuery, useMutation, useQueryClient } from "react-query";

const FetchCoachList = (body) => {
  const method = "GET";
  let url = `api/admin/coach/list?`;
  if (body?.search) {
    url += `search=${body?.search}`;
  }
  if (body?.sorts[0]?.desc) {
    url += `&order=desc`;
  } else {
    url += `&order=asc`;
  }
  if (body?.sorts[0]?.id) {
    url += `&field=${body?.sorts[0]?.id}`;
  }
  url += `&page=${body?.page || 1}&perpage=${body?.per_page || 10}`;
  return useAPI({ method, url });
};

const UpdateCoachStatus = (body) => {
  const method = "PATCH";
  const url = `api/admin/update/coach/status`;
  return useAPI({ method, url, body });
};
const FetchCoachDetail = (username) => {
  const method = "GET";
  const url = `api/admin/coach/${username}`;
  return useAPI({ method, url });
};
const FetchUserCheckinsStandard = (uuid) => {
  const method = "GET";
  const url = `api/admin/user/${uuid}/check-ins/standard`;
  return useAPI({ method, url });
};
const FetchUserCheckinsPhq9 = (uuid) => {
  const method = "GET";
  const url = `api/admin/user/${uuid}/check-ins/phq-9`;
  return useAPI({ method, url });
};

const FetchUserCheckinsGad7 = (uuid) => {
  const method = "GET";
  const url = `api/admin/user/${uuid}/check-ins/gad-7`;
  return useAPI({ method, url });
};

export const FetchUserCheckinsPhq9Query = (payload) => {
  return useQuery(
    [`USER_CHECKINS_PHQ9_${payload}`, payload],
    () => FetchUserCheckinsPhq9(payload),

    {
      enabled: Boolean(payload) ? true : false,
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};

export const FetchUserCheckinsGad7Query = (payload) => {
  return useQuery(
    [`USER_CHECKINS_GAD7_${payload}`, payload],
    () => FetchUserCheckinsGad7(payload),

    {
      enabled: Boolean(payload) ? true : false,
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};
export const FetchUserCheckinsStandardQuery = (payload) => {
  return useQuery(
    [`USER_CHECKINS_STANDARD_${payload}`, payload],
    () => FetchUserCheckinsStandard(payload),

    {
      enabled: Boolean(payload) ? true : false,
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};

const FetchGeneralUsersList = (body) => {
  const method = "GET";
  let url = `api/admin/user/list?`;

  if (body?.search) {
    url += `search=${encodeURIComponent(body?.search)}&`;
  }

  url += `order=${body?.sorts[0]?.desc ? "desc" : "asc"}&`;

  if (body?.sorts[0]?.id) {
    url += `field=${encodeURIComponent(body?.sorts[0]?.id)}&`;
  }

  if (body?.subscriptionFilter) {
    url += `subscriptions_type=${encodeURIComponent(body.subscriptionFilter)}&`;
  }

  url += `page=${body?.page || 1}&perpage=${body?.per_page || 10}`;

  return useAPI({ method, url });
};

const UpdateGeneralUsersStatus = (body) => {
  const method = "PATCH";
  const url = `api/admin/update/user/status`;
  return useAPI({ method, url, body });
};
const FetchGeneralUsersDetail = (uuid) => {
  const method = "GET";
  const url = `api/admin/user/${uuid}`;
  return useAPI({ method, url });
};
export const FetchGeneralUsersDetailQuery = (payload) => {
  return useQuery(
    [`USER_DETAIL_${payload}`, payload],
    () => FetchGeneralUsersDetail(payload),

    {
      enabled: Boolean(payload) ? true : false,
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};
export const FetchGeneralUsersListQuery = (payload) => {
  return useQuery(
    ["GENERAL_USERS_LIST", payload],
    () => FetchGeneralUsersList(payload),
    {
      enabled: Boolean(payload?.page && payload?.per_page),
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};
export const UpdateGeneralUsersStatusQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateGeneralUsersStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("GENERAL_USERS_LIST");
      responseToast.toastSuccess("User status updated");
    },
    onError: ErrorHandler,
  });
};

export const FetchCoachListQuery = (payload) => {
  return useQuery(["COACHES_LIST", payload], () => FetchCoachList(payload), {
    enabled: Boolean(payload?.page && payload?.per_page),
    onSuccess: () => null,
    onError: ErrorHandler,
  });
};
export const UpdateCoachStatusQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateCoachStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("COACHES_LIST");
      responseToast.toastSuccess("Coach status updated");
    },
    onError: ErrorHandler,
  });
};
export const FetchCoachDetailQuery = (payload) => {
  return useQuery(
    [`COACH_DETAIL_${payload}`, payload],
    () => FetchCoachDetail(payload),
    {
      enabled: Boolean(payload) ? true : false,
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};
