/** @format */

import { lazy, Suspense } from "react";
import { Route, Routes, Outlet, Navigate, useLocation } from "react-router-dom";
import RouteConstants from "./RouteConstants";
import { Backdrop, CircularProgress } from "@mui/material";
import { useIsFetching, useIsMutating } from "react-query";
import { Layout } from "components";

/* Login */
const Login = lazy(() => import("pages/Login"));

/* Profile */
const Profile = lazy(() => import("pages/Profile"));

/* Dashboard */
const Dashboard = lazy(() => import("pages/Dashboard"));

/* User Management */
const CoachTherapist = lazy(() =>
  import("pages/UserManagement/CoachTherapist")
);
const GeneralUsers = lazy(() => import("pages/UserManagement/GeneralUsers"));
const CoachDetail = lazy(() => import("pages/UserManagement/CoachDetail"));
const UserDetail = lazy(() => import("pages/UserManagement/UserDetail"));

/* Course Management */
const DailyPractice = lazy(() =>
  import("pages/CourseManagement/DailyPractice")
);
const DailyPracticeDetail = lazy(() =>
  import("pages/CourseManagement/DailyPracticeDetail")
);
const MyCourses = lazy(() => import("pages/CourseManagement/MyCourses"));
const CourseDetail = lazy(() => import("pages/CourseManagement/CourseDetail"));
const LessonDetail = lazy(() =>
  import("pages/CourseManagement/CourseDetail/LessonDetail")
);

/* Habits Management */
const MyHabits = lazy(() => import("pages/MyHabits"));
const HabitDetail = lazy(() => import("pages/MyHabits/HabitDetail"));

/* Approvals Management */
const ProfileApprovals = lazy(() => import("pages/Approvals/ProfileApprovals"));
const ProfileDetail = lazy(() =>
  import("pages/Approvals/ProfileApprovals/ProfileDetail")
);
const HabitApprovals = lazy(() => import("pages/Approvals/HabitApprovals"));
const HabitAction = lazy(() =>
  import("pages/Approvals/HabitApprovals/HabitAction")
);

const Promotion = lazy(() => import("pages/Promotion"));
const NoteApprovals = lazy(() => import("pages/Approvals/NoteApprovals"));
const NoteDetail = lazy(() =>
  import("pages/Approvals/NoteApprovals/NoteDetail")
);

const NotFound404 = lazy(() => import("pages/404"));

const Index = () => {
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();

  return (
    <>
      <div className='hide-scrollbar'>
        <Suspense fallback={<Backdrop open={true} />}>
          <Routes>
            <Route path={RouteConstants.LOGIN} element={<Login />} />

            <Route element={<ProtectedRoutes />}>
              {/* Dashboard */}
              <Route path={"/"} element={<Dashboard />} />
              <Route path={RouteConstants.DASHBOARD} element={<Dashboard />} />
              <Route path={RouteConstants.PROMOTION} element={<Promotion />} />

              {/* User Management */}
              <Route
                path={RouteConstants.COACH_THERAPIST}
                element={<CoachTherapist />}></Route>
              <Route
                path={RouteConstants.COACH_DETAIL}
                element={<CoachDetail />}
              />
              <Route
                path={RouteConstants.GENERAL_USERS}
                element={<GeneralUsers />}
              />
              <Route
                path={RouteConstants.USER_DETAIL}
                element={<UserDetail />}
              />

              {/* Course Management */}
              <Route
                path={RouteConstants.DAILY_PRACTICE}
                element={<DailyPractice />}
              />
              <Route path={RouteConstants.MY_COURSES} element={<MyCourses />} />
              <Route
                path={RouteConstants.COURSE_DETAIL}
                element={<CourseDetail />}
              />
              <Route
                path={RouteConstants.LESSON_DETAIL}
                element={<LessonDetail />}
              />
              <Route
                path={RouteConstants.DAILY_PRACTICE_DETAIL}
                element={<DailyPracticeDetail />}
              />

              {/* My Habits */}
              <Route path={RouteConstants.MY_HABITS} element={<MyHabits />} />
              <Route
                path={RouteConstants.HABIT_DETAIL}
                element={<HabitDetail />}
              />

              {/* Approvals */}
              <Route
                path={RouteConstants.PROFILE_APPROVALS}
                element={<ProfileApprovals />}
              />
              <Route
                path={RouteConstants.PROFILE_DETAIL}
                element={<ProfileDetail />}
              />

              <Route
                path={RouteConstants.HABIT_APPROVALS}
                element={<HabitApprovals />}
              />
              <Route
                path={RouteConstants.HABIT_ACTION}
                element={<HabitAction />}
              />
              <Route
                path={RouteConstants.NOTE_APPROVALS}
                element={<NoteApprovals />}
              />
              <Route
                path={RouteConstants.NOTE_DETAIL}
                element={<NoteDetail />}
              />

              {/* My Profile */}
              <Route path={RouteConstants.MY_PROFILE} element={<Profile />} />
              {/* Not Found */}
              <Route path='*' element={<NotFound404 />} />
            </Route>

            <Route path='*' element={<NotFound404 />} />
          </Routes>
        </Suspense>
        <Backdrop
          open={Boolean(runningAPICount || mutatingAPICount)}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 100,
            backgroundColor: "rgba(0,0,0,0.3)",
          }}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

const ProtectedRoutes = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("token");
  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={RouteConstants.LOGIN} replace state={{ from: location }} />
  );
};

export default Index;
