import axios from "axios";
import RouteConstants from "routes/RouteConstants";

/**
 * API wrapper
 * will handle all configurations and defaults for the api calls
 * Please use this wrapper for all the api calls
 */

const Api = ({
  method,
  url,
  body,
  contentType = "application/json",
  params,
}) => {
  return new Promise((resolve, reject) => {
    const Authorization = `Bearer ${localStorage.getItem("token")}`;

    const headers = {
      "content-type": contentType,
    };
    if (Boolean(localStorage.getItem("token"))) {
      headers.Authorization = Authorization;
    }

    const payload = {
      method,
      url:
        url.split(".").includes("json") || url.includes("http")
          ? url
          : `${process.env.REACT_APP_API_URL}${url}`,
      headers,
      params,
    };

    // including 'data' if body is not null
    if (body != null) {
      payload.data = body;
    }

    axios(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error?.response?.data);
        if (
          error?.response?.status === 401 ||
          error?.response?.data?.message === "Unauthenticated."
        ) {
          localStorage.clear();
          window.location = RouteConstants.LOGIN;
        }
      });
  });
};
export default Api;
