import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * Theme
 */
const commonTheme = createTheme({
  palette: {
    primary: {
      main: "#264265",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          textTransform: "inherit",
          boxShadow: "none",
          padding: "0.75rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontsize: "1rem",
          "&:disabled": {
            cursor: "not-allowed",
          },
        },
      },
    },
  },
});

// Do your common overrides here
/**
 * Typography - body1
 */
commonTheme.typography.body1.fontSize = "0.8rem";
commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
commonTheme.typography.caption.color = commonTheme.palette.text.hint;

/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
